.reactEasyCrop_CropArea {
    position: absolute;
    display: none;
}
.controls{
    width: 100%;
    background-color: white;
    margin-bottom: '20px'!important;
}
.cambg{
    background-color: rgb(34, 33, 33);
    border-radius: 4px;
}