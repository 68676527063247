.reactEasyCrop_CropArea {
    position: absolute;
    display: none;
}

.controls {
    width: 100%;
    background-color: white;
    margin-bottom: '20px' !important;
}

.cambg {
    background-color: rgb(34, 33, 33);
    border-radius: 4px;
    position: relative;
}

.image-holer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

}
.image-holer img{
    max-width: 100%;
    max-height: 100%;
}