@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap');

.landing img {
  max-width: 100%;
}
.landing header {
  padding: 15px;
  position: absolute;
  z-index: 999;
  left: 0;right: 0;

}
.landing .logo{
  color: white;
}
body {
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.landing li{
  list-style: none;
}
.showcase {
  position: absolute;
  right: 0;
  width: 100%;
  min-height: 100vh;
  padding: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #111;
  transition: all 0.3s ease;
  z-index: 2;
}

.showcase video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 0.25;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #78c3fb;
  mix-blend-mode: overlay;
}

.social {
  position: absolute;
  z-index: 10;
  bottom: 1.25em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.social li a {
  display: inline-block;
  filter: invert(1);
  transform: scale(0.5);
  transition: transform 0.3s ease-in;
}

.social li a:hover {
  transform: scale(0.5) translateY(-0.94em);
}

.social>*+* {
  margin-left: 1.25em;
}
.landing .text{
  padding: 20px;
  color: white;
  z-index: 999;
}
.landing h1,.landing h2,.landing h3,.landing h4{
  margin: 0;
  padding:0;
  text-transform: uppercase;
}
